import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchTerm, setSearchTerm }) => {
  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder="Search..."
      fullWidth
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
      }}
      sx={{
        backgroundColor: "white",
        borderRadius: "8px",
        "& .MuiOutlinedInput-root": {
          "& fieldset": { borderColor: "#ddd" },
          "&:hover fieldset": { borderColor: "#aaa" },
          "&.Mui-focused fieldset": { borderColor: "#1976D2" },
        },
      }}
    />
  );
};

export default SearchBar;
