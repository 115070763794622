import { Skeleton } from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";

const AITypingIndicator = () => {
  return (
    <div className="flex justify-end px-4">
      <div className="bg-white text-gray-800 border border-gray-300 rounded-xl p-4 max-w-xs sm:max-w-md shadow-md flex items-center space-x-3">
        <SmartToyIcon fontSize="small" className="text-blue-500" />
        <div>
          <p className="text-sm italic text-gray-600">AI is generating a response...</p>
          <div className="mt-2 space-y-2">
            <Skeleton variant="text" width={180} height={12} />
            <Skeleton variant="text" width={150} height={12} />
            <Skeleton variant="text" width={120} height={12} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AITypingIndicator;
