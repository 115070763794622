import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  accessToken?: string | null | undefined;
  refreshToken?: string | null | undefined;
  user: {
    name?: string;
    email?: string;
    isActive?: boolean;
    pms?: string;
  } | null;
}

const initialState: AuthState = {
  accessToken: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken"),
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") as string)
    : { name: "", email: "", isActive: false }, // Ensure default values
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokens: (
      state,
      action: PayloadAction<{
        accessToken?: string;
        refreshToken?: string;
        user: { name?: string; email?: string; isActive?: boolean; pms?: string };
      }>
    ) => {
      // Update tokens if provided
      if (action.payload.accessToken) {
        state.accessToken = action.payload.accessToken;
      }
      if (action.payload.refreshToken) {
        state.refreshToken = action.payload.refreshToken;
      }

      // Initialize user if it's null
      if (!state.user) {
        state.user = { name: "", email: "", isActive: false }; // Set default values
      }

      // Update user data
      state.user = {
        ...state.user, // Preserve existing user data
        ...action.payload.user, // Update only the provided fields
      };

      // Store updated data in localStorage
      localStorage.setItem(
        "accessToken",
        action.payload.accessToken || state.accessToken || ""
      );
      localStorage.setItem(
        "refreshToken",
        action.payload.refreshToken || state.refreshToken || ""
      );
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    clearTokens: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = { name: "", email: "", isActive: false }; // Reset user to default values

      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("user");
    },
  },
});

export const { setTokens, clearTokens } = authSlice.actions;
export default authSlice.reducer;
