import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSyncStatus, triggerSync } from "../../redux/syncSlice";
import { RootState, AppDispatch } from "../../redux/store";
import {
  MenuItem,
  MenuList,
  Divider,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CloudSyncIcon from "@mui/icons-material/CloudSync";

export default function CustomMenu() {
  const dispatch = useDispatch<AppDispatch>();
  const { isSyncing, lastSyncDateTime } = useSelector(
    (state: RootState) => state.sync
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent menu from closing
    setIsModalOpen(true);
  };

  const handleCloseModal = (event: React.MouseEvent) => {
    if (event) event.stopPropagation(); // Prevent menu from closing when closing modal
    setIsModalOpen(false);
  };

  const handleConfirmSync = () => {
    dispatch(triggerSync());
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(fetchSyncStatus());
    const interval = setInterval(() => dispatch(fetchSyncStatus()), 15000);
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <>
      <MenuList dense disablePadding>
        <MenuItem
          component="button"
          sx={{ justifyContent: "flex-start", width: "100%" }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem
          disabled={isSyncing}
          onClick={handleOpenModal} // Open the modal
          component="button"
          sx={{ justifyContent: "flex-start", width: "100%" }}
        >
          <ListItemIcon>
            <CloudSyncIcon />
          </ListItemIcon>
          {isSyncing ? "Syncing..." : "Sync Now"}
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          {lastSyncDateTime && (
            <p className="mt-2 text-sm">
              Last Sync: {new Date(lastSyncDateTime).toLocaleString("en-GB")}
            </p>
          )}
        </MenuItem>
      </MenuList>

      {/* Confirmation Modal */}
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Confirm Sync</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to start the sync process? This may take a
            while.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmSync}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
