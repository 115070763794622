import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ChannelParams, fetchChannelData } from "../../../services/targetAPI";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { DefaultOptionType } from "antd/es/select";
import FilterDropdown from "../../../components/FilterDropdown";
import { ChannelData } from "../portfolio/types";
import { fetchDropdownValues } from "../../../services/bookingAPI";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import NumberConverter from "../../../utils/NumberFormatter";

const getMonthColumns = (): ColumnsType<ChannelData> => {
  const months = Array.from({ length: 12 }, (_, index) => {
    const monthName = new Date(0, index).toLocaleString("default", {
      month: "short",
    });
    return {
      title: monthName,
      dataIndex: monthName,
      key: monthName,
      width: 80,
      align: "center" as "center",
      render: (value: number | string | null) =>
        value !== "0.00" ? (
          <span className="font-semibold">{value}%</span>
        ) : (
          <span className="font-semibold">0</span>
        ),
    };
  });
  return months;
};

const ChannelPerformanceTable: React.FC<{
  isLoading: boolean;
}> = ({isLoading}) => {
  const [columns, setColumns] = useState<ColumnsType<ChannelData>>([]);
  const [data, setData] = useState<ChannelData[]>([]);
  const [currentYear, setCurrentYear] = useState<number>(dayjs().year());
  const [listings, setListings] = useState<string[]>([]);
  const [listingValues, setListingValues] = useState<DefaultOptionType[]>([]);

  const fetchChannelDataFromAPI = useCallback(
    async (val?: any) => {
      try {

        const params: ChannelParams = {
          year: currentYear,
          listing_name: listings.join(","),
        };

        const response = await fetchChannelData({
          params,
        });

        console.log("API Response:", response);

        const transformedData = response.results.map((channel: any) => {
          const row: any = { channelName: channel.channel_name };

          channel.contribution_percent.forEach((monthData: any) => {
            const monthName = new Date(monthData.month).toLocaleString(
              "default",
              { month: "short" }
            );
            row[monthName] = monthData.contribution_percent;
          });

          row.expandedData = channel.contribution_percent;

          return row;
        });

        setData(transformedData);
      } catch (error) {
        console.error("Failed to fetch channels:", error);
        setListingValues([]);
      } finally {
      }
    },
    [currentYear, listings]
  );

  const fetchDropdownLoad = useCallback(async () => {
    try {
      const response = await fetchDropdownValues();
      const distinct_listings = response?.data?.distinct_listings || [];
      const listingsValues = distinct_listings.map((val: string) => ({
        label: val,
        value: val,
      }));

      setListingValues(listingsValues);
    } catch (error) {
      console.error("Failed to fetch dropdown values:", error);
      setListingValues([]);
    }
  }, []);

  useEffect(() => {
    const monthColumns = getMonthColumns();
    setColumns([
      {
        title: "",
        dataIndex: "channelName",
        key: "channelName",
        fixed: "left",
        align: "center",
        width: "10%",
        className: "!pl-2 text-blue-800 cursor-pointer font-semibold",
        render: (value: string, _record: any, index: number) => (
          value === "direct" ? "Direct" : <span
            className={
              index < 2
                ? "text-blue-800 cursor-pointer"
                : "text-black cursor-pointer"
            }
          >
            {value}
          </span>
        ),
      },
      ...monthColumns,
    ]);

    fetchChannelDataFromAPI();
  }, [currentYear, listings]);

  const renderExpandedTable = (record: any) => {
    if (!record.expandedData || record.expandedData.length === 0) {
      return <div>No data available</div>;
    }

    const metrics = ["Revenue", "Booked Nights", "ADR"];

    const dataForExpandedTable = metrics.map((metric) => {
      const row: any = { key: metric, metric };

      record.expandedData.forEach((monthData: any) => {
        const monthName = new Date(monthData.month).toLocaleString("default", {
          month: "short",
        });
        if (metric === "Revenue") {
          row[monthName] = monthData.revenue || "0.00";
        } else if (metric === "Booked Nights") {
          row[monthName] = monthData.booked_nights || "0";
        } else if (metric === "ADR") {
          row[monthName] = monthData.adr || "0.00";
        }
      });

      return row;
    });

    const monthColumns = Array.from({ length: 12 }, (_, index) => {
      const monthName = new Date(0, index).toLocaleString("default", {
        month: "short",
      });
      return {
        title: "",
        dataIndex: monthName,
        key: monthName,
        align: "center" as const,
        width: 80,
        render: (value: any) =>
          value !== "0.00" ? (
            <span className="font-semibold"><NumberConverter value={value} /></span>
          ) : (
            <span className="font-semibold">0</span>
          ),
      };
    });

    const expandedColumns: ColumnsType<any> = [
      {
        title: "",
        dataIndex: "metric",
        key: "metric",
        align: "left" as const,
        width: 158,
        render: (value: string) => (
          <span className="!pl-3 font-semibold">{value}</span>
        ),
      },
      ...monthColumns,
    ];

    return (
      <Table
        columns={expandedColumns}
        dataSource={dataForExpandedTable}
        pagination={false}
        bordered
        className="!bg-white border-2 shadow-lg rounded-lg"
      />
    );
  };

  useEffect(() => {
    fetchDropdownLoad();
  }, []);

  return (
    <>
      <div className="flex inline-flex w-full justify-between space-x-4 items-center bg-white px-5 py-3 mt-3 rounded-lg shadow-lg">
        <div className="flex justify-start gap-4 w-1/2">
          <DatePicker
            picker="year"
            value={dayjs(`${currentYear}`, "YYYY")}
            onChange={(date) => {
              if (date) {
                setCurrentYear(date.year());
              }
            }}
            allowClear={false}
            className="w-1/8"
          />
          <div className="w-1/2">
            <FilterDropdown
              options={listingValues}
              placeholder="Select Listings"
              selectedValues={listings}
              setSelectedValues={setListings}
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="channelName"
        bordered
        pagination={false}
        scroll={{ x: "100%", y: 400 }}
        sticky
        className="bg-white"
        loading={isLoading}
        expandable={{
          expandedRowRender: (record) => renderExpandedTable(record),
          rowExpandable: () => true,
          expandRowByClick: true,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined
                onClick={(e) => {
                  e.stopPropagation(); // Prevent event bubbling
                  onExpand(
                    record,
                    e as unknown as React.MouseEvent<HTMLElement, MouseEvent>
                  );
                }}
              />
            ) : (
              <DownOutlined
                onClick={(e) => {
                  e.stopPropagation(); // Prevent event bubbling
                  onExpand(
                    record,
                    e as unknown as React.MouseEvent<HTMLElement, MouseEvent>
                  );
                }}
              />
            ),
        }}
      />
      <style>
        {`
          .ant-table.ant-table-bordered{
            margin:0!important;
          }
          .ant-table-cell{
            padding-left:0!important;
            padding-right:0!important;
          }
          .ant-table-expanded-row-fixed{
            padding: 0!important
          }
          .ant-table-expanded-row-fixed .ant-table-thead{
            display:none;
          }
          `}
      </style>
    </>
  );
};

export default ChannelPerformanceTable;
