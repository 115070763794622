import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  addMessage,
  approveMessage,
  setMessages,
  setProperty,
  setReservation,
} from "../../../../redux/chatSlice";
import ChatHeader from "./ChatHeader";
import ChatMessages from "./ChatMessages";
import MessageInput from "../MessageInput";
import ProposedReplyModal from "../ProposedReplyModal";
import { approveAIReply, fetchMessageByID } from "../../../../services/chatAPI";

const NAVBAR_HEIGHT = 60;
const token = localStorage.getItem("accessToken");
const BACKENDBASEURL = process.env.REACT_APP_SOCKET_BASE_URL;

const ChatWindow = () => {
  const dispatch = useDispatch();
  const selectedConversation = useSelector(
    (state: RootState) => state.chat.selectedConversation
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSavingLoading, setIsSavingLoading] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<any | null>(null);
  const [aiMessagePendingApproval, setAiMessagePendingApproval] =
    useState<any>(null);

  const messages = useSelector((state: RootState) =>
    selectedConversation ? state.chat.messages[selectedConversation] || [] : []
  );

  const fetchMessages = async () => {
    if (!selectedConversation) return;

    try {
      setIsLoading(true);
      const response = await fetchMessageByID(selectedConversation);

      const transformedMessages = response.data.messages
        .map((msg: any) => ({
          ...msg,
          timestamp: new Date(msg.sent_at || msg.received_at),
        }))
        .sort(
          (a: any, b: any) => a.timestamp.getTime() - b.timestamp.getTime()
        );

      dispatch(
        setMessages({
          conversationId: selectedConversation,
          messages: transformedMessages,
        })
      );

      if (response.data.reservation) {
        dispatch(
          setReservation({
            conversationId: selectedConversation,
            reservation: response.data.reservation, // Now in the required format
          })
        );
      }

      if (response.data.property) {
        dispatch(
          setProperty({
            conversationId: selectedConversation,
            property: response.data.property,
          })
        );
      }
    } catch (err) {
      console.error("Failed to fetch messages", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [selectedConversation]);

  useEffect(() => {
    if (!selectedConversation) return;

    const socket = new WebSocket(
      `${
        window.location.protocol === "https:" ? "wss" : "ws"
      }://${BACKENDBASEURL}/ws/chat/${selectedConversation}/?token=${token}`
    );

    socket.onopen = () => console.log("WebSocket connected");

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      // ✅ If an update is detected, re-fetch all messages
      if (data.conversation_id === selectedConversation) {
        fetchMessages(); // Fetch latest messages when there is a change
      }
    };

    socket.onerror = (error) => console.error("WebSocket error:", error);
    socket.onclose = () => console.log("WebSocket disconnected");

    return () => socket.close();
  }, [selectedConversation, fetchMessages]);

  const conversation = useSelector((state: RootState) =>
    selectedConversation
      ? state.chat.conversations.find(
          (conv) => conv?.conversation_id === selectedConversation
        )
      : null
  );

  const isGenerating = conversation?.isGenerating || false;
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (message: any) => {
    const aiMessagePendingApproval = messages.find(
      (msg: any) => msg.sender === "guest" && msg.id === message?.message
    );
    setSelectedMessage(aiMessagePendingApproval);
    setAiMessagePendingApproval(message);

    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedMessage(null);
    setAiMessagePendingApproval(null);
    setModalOpen(false);
  };
  const handleDeclineReply = async () => {
    if (!selectedConversation || !aiMessagePendingApproval) return;

    try {
      setIsSavingLoading(true);
      await approveAIReply(aiMessagePendingApproval?.id, "", true);

      dispatch(
        approveMessage({
          conversationId: selectedConversation,
          messageId: aiMessagePendingApproval.id,
          declined: true,
        })
      );

      setModalOpen(false);
      fetchMessages();
    } catch (err) {
      console.error("Failed to decline AI reply:", err);
    } finally {
      setIsSavingLoading(false);
    }
  };

  const handleApproveReply = async (finalReply: string) => {
    if (!selectedConversation || !aiMessagePendingApproval) return;

    try {
      setIsSavingLoading(true);
      await approveAIReply(aiMessagePendingApproval?.id, finalReply);

      dispatch(
        approveMessage({
          conversationId: selectedConversation,
          messageId: aiMessagePendingApproval.id,
          content: finalReply,
        })
      );
      setModalOpen(false);

      fetchMessages(); // Refresh messages
    } catch (err) {
      console.error("Failed to approve AI reply:", err);
    } finally {
      setIsSavingLoading(false);
    }
  };

  if (!selectedConversation) {
    return (
      <div className="flex-1 flex items-center justify-center text-gray-500">
        Select a conversation
      </div>
    );
  }

  return (
    <div
      className="flex-1 flex flex-col space-y-2 gap-2 py-2"
      style={{ height: `calc(100vh - ${NAVBAR_HEIGHT}px)` }}
    >
      <ChatHeader name={conversation?.name} />
      <ChatMessages
        messages={messages}
        isGenerating={isGenerating}
        setModalOpen={setModalOpen}
        handleOpenModal={handleOpenModal}
      />
      <MessageInput />

      {aiMessagePendingApproval && (
        <ProposedReplyModal
          open={modalOpen}
          handleOpenModal={handleOpenModal}
          guestMessage={selectedMessage?.content || ""}
          aiReply={aiMessagePendingApproval.content}
          onApprove={handleApproveReply}
          onDecline={handleDeclineReply}
          onClose={() => handleCloseModal()}
          isSaving={isSavingLoading}
        />
      )}
    </div>
  );
};

export default ChatWindow;
