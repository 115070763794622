import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";

const AIIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.8"
    stroke="currentColor"
    className="w-5 h-5 text-blue-300"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
    />
  </svg>
);

const ApprovalBadge = () => (
  <span className="absolute -top-2 left-3 bg-yellow-600 text-white text-xs font-semibold px-2 py-1 rounded-md shadow-md cursor-pointer hover:scale-105 transition-transform min-w-12">
    Needs Approval
  </span>
);

const MessageBubble = ({
  message,
  setModalOpen,
  handleOpenModal,
}: {
  message: any;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenModal: (message: any) => void;
}) => {
  const isGuest = message.sender === "guest";
  const isAI = message.ai_generated;
  const needsApproval = message.needs_approval;

  return (
    <div
      className={`flex min-w-12 ${isGuest ? "justify-start" : "justify-end"} px-2 py-2`}
    >
      <div
        className={`relative max-w-xs sm:max-w-md p-3 rounded-xl shadow-md mb-2 transition-all duration-200 break-words border
    ${isGuest ? "bg-white text-gray-800 border-gray-300 rounded-tl-none" : "bg-blue-500 text-white rounded-tr-none"}
    ${needsApproval ? "border border-yellow-500 bg-yellow-50 !text-gray-900" : ""}
  `}
      >
        {/* AI Indicator */}
        {isAI && (
          <div className="absolute top-1 right-1 opacity-80">
            <Tooltip title="AI generated content" placement="top">
              <span>
                <AIIcon />
              </span>
            </Tooltip>
          </div>
        )}

        {/* Needs Approval Badge */}
        {needsApproval && (
          <div onClick={() => handleOpenModal(message)}>
            <ApprovalBadge />
          </div>
        )}

        {/* Message Content */}
        <p className="text-sm leading-relaxed break-words">{message.content}</p>

        {/* Timestamp */}
        <p
          className={`text-xs text-right mt-1 ${isGuest || needsApproval ? "text-gray-500" : "text-white/80"}`}
        >
          {dayjs(
            message.sender === "guest" ? message.received_at : message.sent_at
          ).format("h:mm A")}
        </p>
      </div>
    </div>
  );
};

export default MessageBubble;
