import { Paper, Typography, Divider, Box } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import ApartmentIcon from '@mui/icons-material/Apartment';

const PropertyPanel = () => {
  const selectedConversation = useSelector(
    (state: RootState) => state.chat.selectedConversation
  );
  const properties = useSelector((state: RootState) => state.chat.properties);

  const property = selectedConversation
    ? properties[selectedConversation]
    : null;

  if (!property) return null;

  return (
    <Paper
      elevation={3}
      className="p-5"
      sx={{
        width: "350px",
        borderLeft: "1px solid #ddd",
        display: "flex",
        flexDirection: "column",
        maxHeight: "50vh", // Ensures the panel does not exceed half the screen height
        minHeight: "50vh", // Ensures the panel does not exceed half the screen height
        borderRadius: 2,
        backgroundColor: "#fff",
      }}
    >
      {/* Header */}
      <Typography variant="h6" fontWeight="bold" color="primary" gutterBottom>
        Property Details
      </Typography>
      <Divider sx={{ mb: 2 }} />

      {/* Property Name */}
      <Box display="flex" alignItems="center" gap={1} mb={1}>
        <ApartmentIcon color="action" />
        <Typography variant="body1" fontWeight="bold">
          {property.name}
        </Typography>
      </Box>

      {/* Property Address */}
      <Box display="flex" alignItems="center" gap={1} mb={2}>
        <LocationOnIcon color="action" />
        <Typography variant="body2" color="textSecondary">
          {property.address}
        </Typography>
      </Box>

      {/* Amenities Section */}
      <Typography variant="body2" fontWeight="bold" gutterBottom>
        Amenities:
      </Typography>

      <Box
        sx={{
          maxHeight: "25vh", // Limits scrolling to this section
          overflowY: "auto",
          paddingRight: 1,
        }}
      >
        {property?.amanities && property?.amanities?.map((amenity, index) => (
          <Box key={index} display="flex" alignItems="center" gap={1}>
            <CheckIcon color="success" fontSize="small" />
            <Typography variant="body2" color="textSecondary">
              {amenity}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default PropertyPanel;
