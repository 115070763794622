import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import { useState } from "react";

interface Props {
  open: boolean;
  guestMessage: string;
  aiReply: string;
  onApprove: (finalReply: string) => void;
  onClose: () => void;
  isSaving: boolean;
  handleOpenModal:(message: any) => void
}

const ProposedReplyModal = ({
  open,
  guestMessage,
  aiReply,
  onApprove,
  onDecline,
  onClose,
  isSaving,
}: Props & { onDecline: () => void }) => {
  const [editedReply, setEditedReply] = useState(aiReply);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#F1F5F9",
          color: "#1E293B",
          fontSize: "1.25rem",
          fontWeight: "bold",
          padding: "16px 24px",
          borderBottom: "1px solid #E2E8F0",
        }}
      >
        AI Suggested Reply
      </DialogTitle>

      <DialogContent sx={{ padding: "24px" }}>
        <p
          style={{
            color: "#475569",
            fontWeight: "500",
            marginBottom: "12px",
            lineHeight: "1.5",
          }}
        >
          <span style={{ color: "#1E293B", fontWeight: "600" }}>Guest:</span> "
          {guestMessage}"
        </p>

        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={editedReply}
          onChange={(e) => setEditedReply(e.target.value)}
          sx={{
            backgroundColor: "#F8FAFC",
            borderRadius: "8px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              padding: "12px",
            },
          }}
        />
      </DialogContent>

      <DialogActions
        sx={{
          padding: "16px 24px",
          backgroundColor: "#F8FAFC",
          borderTop: "1px solid #E2E8F0",
        }}
      >
        <Button onClick={onClose} sx={{ color: "#64748B", fontWeight: "500" }}>
          Cancel
        </Button>
        <Button
          onClick={onDecline}
          sx={{
            color: "#DC2626",
            fontWeight: "600",
            borderRadius: "8px",
            "&:hover": { backgroundColor: "#FECACA" },
          }}
          disabled={isSaving}
        >
          Decline
        </Button>
        <Button
          onClick={() => onApprove(editedReply)}
          variant="contained"
          sx={{
            backgroundColor: "#2563EB",
            fontWeight: "600",
            borderRadius: "8px",
            "&:hover": { backgroundColor: "#1E40AF" },
          }}
          disabled={isSaving}
        >
          Approve & Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProposedReplyModal;
