import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { addMessage } from "../../../redux/chatSlice";
import { Send } from "@mui/icons-material";
import dayjs from "dayjs";
import { createManualReply } from "../../../services/chatAPI";

const MessageInput = () => {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const selectedConversation = useSelector(
    (state: RootState) => state.chat.selectedConversation
  );

  const handleSendMessage = async() => {
    if (!selectedConversation || !message.trim()) return;

    const newMessage = {
      id: new Date().toISOString(),
      sender: "ai",
      content: message,
      conversationId: selectedConversation,
      timestamp: "",
    };
    await createManualReply(selectedConversation, newMessage?.id, message)
    dispatch(
      addMessage({ conversationId: selectedConversation, message: newMessage })
    );
    setMessage("");
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div className="w-full flex pb-2">
      <div className="bottom-0 mb-2 w-full bg-white shadow-lg rounded-full flex items-center p-3 border border-gray-300 z-50">
        <input
          className="flex-1 p-2 outline-none border-none text-gray-700 bg-transparent"
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <button
          className="bg-blue-500 text-white rounded-full p-2 hover:bg-blue-600 transition-all"
          onClick={handleSendMessage}
        >
          <Send fontSize="small" />
        </button>
      </div>
    </div>
  );
};

export default MessageInput;
