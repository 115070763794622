// store.ts
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice"; // Make sure the path is correct
import chatReducer from "./chatSlice";
import syncReducer from "./syncSlice";

const store = configureStore({
  reducer: {
    auth: authReducer, // Add other reducers here if needed
    chat: chatReducer,
    sync: syncReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
