import React from "react";
import type { ColumnsType } from "antd/es/table";
import { Property, Property_Listing } from "./types";
import MonthPicker from "../../../components/MonthPicker";
import dayjs from "dayjs";
import ToggleButton from "../../../components/ToggleButton";
import { REVENUETYPES } from "../portfolio/utils";
import { InputNumber } from "antd";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Chip from "@mui/material/Chip";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton } from "@mui/material";

export const LISTINGS_SETTINGSCOLUMN = (
  rowEditingStates: Record<number, boolean>,
  handleRowEditToggle: (id: number, editing: boolean) => void,
  handleCancel: (id: number) => void,
  handleMainTableEdit: (
    id: string | number,
    field: string,
    value: string | number
  ) => void,
  isSavingLoading: boolean
): ColumnsType<Property> => [
  {
    title: "UID",
    dataIndex: "internal_listing_name",
    key: "internal_listing_name",
    width: "25%",
    render: (text, record) => {
      return (
        <div>
          {record.internal_listing_name}
          <br />
          <span className="text-gray-500">
            {record?.pms_name} | {record?.listingid}
          </span>
        </div>
      );
    },
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "start_date",
    width: "20%",
    align: "center",
    render: (text, record) =>
      rowEditingStates[record.id] ? (
        <MonthPicker
          defaultValue={text ? dayjs(text) : undefined}
          isMonthly={false}
          isDate={true}
          allowClear={true}
          onSelect={(value: any) =>
            handleMainTableEdit(
              record?.id,
              "start_date",
              value ? value?.format("YYYY-MM-DD") : ""
            )
          }
        />
      ) : text ? (
        dayjs(text).format("DD-MM-YYYY").toString()
      ) : (
        ""
      ),
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    key: "end_date",
    width: "20%",
    align: "center",
    render: (text, record) =>
      rowEditingStates[record.id] ? (
        <MonthPicker
          defaultValue={text ? dayjs(text) : undefined}
          isDate={true}
          isMonthly={false}
          onSelect={(value: any) =>
            handleMainTableEdit(
              record?.id,
              "end_date",
              value ? value?.format("YYYY-MM-DD") : ""
            )
          }
        />
      ) : text ? (
        dayjs(text).format("DD-MM-YYYY").toString()
      ) : (
        ""
      ),
  },
  {
    title: "Commission (%)",
    dataIndex: "pm_fees",
    key: "pm_fees",
    width: "16%",
    align: "center",
    render: (text, record) =>
      rowEditingStates[record.id] ? (
        <InputNumber
          defaultValue={text}
          placeholder="0"
          min={0}
          max={100}
          style={{ width: "80px" }}
          onKeyPress={(e) => {
            // Prevent alphabetic characters
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          onChange={(value) => {
            const sanitizedValue = value > 100 ? 100 : value;
            handleMainTableEdit(record.id, "pm_fees", sanitizedValue);
          }}
        />
      ) : (
        text
      ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "15%",
    align: "center",
    render: (_, record) => {
      const isEditingRow = rowEditingStates[record.id];
      return (
        <div className="flex items-center space-x-4 inline-flex">
          {isEditingRow && (
            <Button
              onClick={() => handleCancel(record.id)}
              style={{
                background: "#4B5563",
                color: "#fff",
              }}
              className="font-bold"
            >
              Cancel
            </Button>
          )}
          <Button
            onClick={() => handleRowEditToggle(record.id, !isEditingRow)}
            style={{
              background: isEditingRow ? "#4caf50" : "#007fff",
              color: "#fff",
            }}
            className="font-bold"
            disabled={isSavingLoading && isEditingRow}
          >
            {isEditingRow ? "Save" : "Edit"}
          </Button>
        </div>
      );
    },
  },
];

export const LISTINGS_PROPERTYTABLE = (
  handleMenuClick: (
    event: React.MouseEvent<HTMLElement>,
    record: Property_Listing
  ) => void,
  handleEditClick: (
    event: React.MouseEvent<HTMLElement>,
    record: Property_Listing
  ) => void
): ColumnsType<Property_Listing> => [
  {
    title: "UID",
    dataIndex: "internal_listing_name",
    key: "internal_listing_name",
    width: "20%",
    render: (text, record) => {
      return (
        <div>
          {record.internal_listing_name}
          <br />
          <span className="text-gray-500">
            {record?.pms_name} | {record?.listingid}
          </span>
        </div>
      );
    },
  },

  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    width: "19%",
    align: "center",
  },
  {
    title: "Bedroom",
    dataIndex: "bedroom",
    key: "bedroom",
    width: "10%",
    align: "center",
  },
  {
    title: "To Do",
    dataIndex: "to_do_flag",
    key: "to_do_flag",
    width: "15%",
    align: "center",
    render: (text, record) => {
      return (
        <span className="flex items-center justify-center">
          {text == true ? (
            <CheckCircleOutlineIcon style={{ color: "rgb(34, 197, 94)" }} />
          ) : (
            <Chip
              label={text}
              color="info"
              size="small"
              variant="outlined"
              onClick={(event) => handleMenuClick(event, record)}
            />
          )}
        </span>
      );
    },
  },
  {
    title: "",
    dataIndex: "edit",
    key: "edit",
    width: "3%",
    align: "center",
    render: (_, record) => (
      <IconButton
        onClick={(event) => handleEditClick(event, record)}
        aria-label="edit"
        size="small"
      >
        <EditIcon style={{ color: "#28a745" }} />
      </IconButton>
    ),
  },

  {
    title: "",
    dataIndex: "button",
    key: "button",
    width: "3%",
    align: "center",
    render: (_, record) => (
      <IconButton
        onClick={(event) => handleMenuClick(event, record)}
        aria-label="settings"
        size="small"
      >
        <SettingsIcon style={{ color: "#0066b2" }} />
      </IconButton>
    ),
  },
];

export const CURRENCYVALUES = [
  { label: "AED", value: "AED" },
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
  { label: "GBP", value: "GBP" },
  { label: "INR", value: "INR" },
];

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
