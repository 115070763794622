import { Avatar } from "@mui/material";
import { getRandomColor } from "../utils";

const ChatHeader = ({ name }: { name?: string }) => {
  return (
    <div className="flex items-center gap-3 bg-white shadow px-4 py-3 rounded-lg shadow-lg">
      <Avatar
        sx={{
          bgcolor: getRandomColor(name || ""),
          color: "white",
          width: "40px",
          height: "40px",
          fontSize: "1rem",
        }}
      >
        {name?.[0] || "G"}
      </Avatar>
      <div>
        <h3 className="text-lg font-semibold">{name || "Guest"}</h3>
        <p className="text-xs text-gray-500">Guest</p>
      </div>
    </div>
  );
};

export default ChatHeader;
