import { useState } from "react";
import { useDispatch } from "react-redux";
import ConversationList from "./LeftPanel";
import ChatWindow from "./ChatWindow/index";
import ReservationPanel from "./ReservationPanel";
import { IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import PropertyPanel from "./PropertyPanel";

const ChatPage = () => {
  const dispatch = useDispatch();
  const [isReservationOpen, setIsReservationOpen] = useState(false);

  return (
    <div
      className="flex overflow-hidden bg-gray-100 px-2 gap-2"
      style={{ height: "calc(100vh - 64px)" }}
    >
      {/* Left Panel - Conversation List */}
      <ConversationList />
      {/* Middle Panel - Chat Window */}
      <div className="flex-1 p-2 flex flex-col">
        <ChatWindow />
      </div>

      {/* Right Panel - Reservation Details (Collapsible) */}
      <div className="flex flex-col h-full justify-around">
        <ReservationPanel />
        <PropertyPanel />
      </div>
    </div>
  );
};

export default ChatPage;
