import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchListingDetails,
  updateListingDetails,
} from "../../../services/targetAPI";
import TextArea from "antd/es/input/TextArea";

interface EditListingModalProps {
  open: boolean;
  onClose: () => void;
  listingId: string | number | null;
}

const EditListingModal: React.FC<EditListingModalProps> = ({
  open,
  onClose,
  listingId,
}) => {
  const [listingData, setListingData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [savingLoading, setSavingLoading] = useState<boolean>(false);
  const [editableData, setEditableData] = useState({
    tower: "",
    floor: "",
    view: "",
    building_amanities: "",
    house_rules: "",
  });

  useEffect(() => {
    if (listingId) {
      fetchDetails();
    }
  }, [listingId]);

  const fetchDetails = async () => {
    setLoading(true);
    try {
      if (listingId) {
        const response = await fetchListingDetails(listingId);
        setListingData(response.data);
        setEditableData({
          tower: response.data.tower || "",
          floor: response.data.floor || "",
          view: response.data.view || "",
          building_amanities: response.data.building_amanities || "",
          house_rules: response.data.house_rules || "",
        });
      }
    } catch (error) {
      console.error("Error fetching listing details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field: string, value: string) => {
    setEditableData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    try {
      if (!listingId) return;
      setSavingLoading(true);
      await updateListingDetails(listingId, editableData);
      onClose();
    } catch (error) {
      console.error("Error updating listing:", error);
    } finally {
      setSavingLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {/* Modal Title with Close Icon */}
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Edit Listing Details
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          {loading ? (
            /* Skeleton Loader */
            Array.from(new Array(6)).map((_, index) => (
              <Grid item xs={6} key={index}>
                <Skeleton variant="rectangular" width="100%" height={90} />
              </Grid>
            ))
          ) : (
            /* Listing Details */
            <>
              {/* Non-editable Fields */}
              <Grid item xs={6}>
                <Typography variant="subtitle2">UID</Typography>
                <TextField
                  fullWidth
                  value={listingData?.internal_listing_name || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Listing Name</Typography>
                <TextField fullWidth value={listingData?.name || ""} disabled />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Description</Typography>
                <TextField
                  fullWidth
                  multiline
                  value={listingData?.description || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Location</Typography>
                <TextField
                  fullWidth
                  value={
                    [
                      listingData?.street,
                      listingData?.city,
                      listingData?.country,
                    ]
                      .filter(Boolean)
                      .join(", ") || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Building</Typography>
                <TextField
                  fullWidth
                  value={listingData?.building || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Location Map</Typography>
                <TextField
                  fullWidth
                  value={listingData?.locationMap || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Check-In Time</Typography>
                <TextField
                  fullWidth
                  value={listingData?.checkin_time || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Check-Out Time</Typography>
                <TextField
                  fullWidth
                  value={listingData?.checkout_time || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Amenities</Typography>
                <TextArea
                  value={
                    listingData?.amanities
                      ? listingData?.amanities?.join(", ")
                      : ""
                  }
                  disabled
                />
              </Grid>

              {/* Editable Fields */}
              <Grid item xs={6}>
                <Typography variant="subtitle2">Tower</Typography>
                <TextField
                  fullWidth
                  value={editableData.tower}
                  onChange={(e) => handleChange("tower", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Floor</Typography>
                <TextField
                  fullWidth
                  value={editableData.floor}
                  onChange={(e) => handleChange("floor", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">View</Typography>
                <TextField
                  fullWidth
                  value={editableData.view}
                  onChange={(e) => handleChange("view", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Building Amenities</Typography>
                <TextField
                  fullWidth
                  multiline
                  value={editableData.building_amanities}
                  onChange={(e) =>
                    handleChange("building_amanities", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">House Rules</Typography>
                <TextField
                  fullWidth
                  multiline
                  value={editableData.house_rules}
                  onChange={(e) => handleChange("house_rules", e.target.value)}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>

      {/* Modal Actions */}
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          disabled={savingLoading || loading}
        >
          Close
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={savingLoading || loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditListingModal;
