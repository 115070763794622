import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Fade,
  IconButton,
  Badge,
  Tooltip,
} from "@mui/material";
import { NAVIGATION } from "./utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../src/assets/media/vividity main.png";
import { useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import CustomMenu from "./CustomMenu";
import { Account } from "@toolpad/core";
import MailIcon from "@mui/icons-material/Mail";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

export function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [hovered, setHovered] = useState(false); // To manage hover state

  const user = useSelector((state: RootState) => state.auth.user);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setHovered(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setHovered(false);
  };

  return (
    <>
      <AppBar
        position="fixed"
        className="!bg-white !shadow-md !h-16"
        style={{ zIndex: 999 }}
      >
        <Toolbar className="flex justify-between items-center px-6">
          {/* Logo */}
          <Link to="/dashboard/home">
            <img
              src={logo}
              height={50}
              width={150}
              className="pt-2"
              alt="Logo"
            />
          </Link>

          {/* Navigation Links */}
          <div className="flex items-center gap-4">
            {NAVIGATION.map((item: any) => {
              const isSelected =
                location.pathname === `/${item.segment}` ||
                (item.title === "Reports" &&
                  location.pathname.startsWith("/dashboard/reports"));

              return (
                <div key={item.segment} className="relative">
                  <Button
                    onClick={() => navigate(`/${item.segment}`)}
                    className={`!font-semibold !capitalize transition-all ${
                      isSelected ? "!text-blue-700" : "!text-gray-600"
                    } hover:!text-blue-600`}
                    style={{
                      borderRadius: "8px",
                    }}
                    onMouseEnter={
                      item.title === "Reports"
                        ? handleMenuOpen
                        : handleMenuClose
                    }
                  >
                    {item.title}
                    {item.title === "Reports" && (
                      <KeyboardArrowDown className="ml-1" />
                    )}
                  </Button>

                  {/* Reports Dropdown Menu */}
                  {item.title === "Reports" && (
                    <Menu
                      id="fade-menu"
                      disableScrollLock
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                        onMouseEnter: () => setHovered(true),
                        onMouseLeave: handleMenuClose,
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleMenuClose}
                      TransitionComponent={Fade}
                      style={{ zIndex: 999 }}
                    >
                      {[
                        { name: "Targets", path: "/dashboard/reports/targets" },
                        {
                          name: "Channel Performance",
                          path: "/dashboard/reports/channelPerformance",
                        },
                      ].map((report) => (
                        <MenuItem
                          key={report.path}
                          onClick={() => {
                            navigate(report.path);
                            handleMenuClose();
                          }}
                          className={`!font-semibold transition-all ${
                            location.pathname === report.path
                              ? "!text-blue-700"
                              : "!text-gray-600"
                          } hover:!bg-gray-100`}
                          style={{ fontSize: 14, padding: "10px 20px" }}
                        >
                          {report.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </div>
              );
            })}
          </div>

          {/* Right Section */}
          <div className="flex items-center gap-4">
            {/* Chatbox Icon */}
            {user?.pms === "Hostaway" && (
              <IconButton
                size="large"
                aria-label="chatbox"
                onClick={() => navigate("/dashboard/chatbox")}
                className={`transition-all rounded-full ${
                  location.pathname === "/dashboard/chatbox"
                    ? "bg-blue-200 shadow-md"
                    : "bg-gray-200 hover:bg-gray-300"
                }`}
                style={{
                  padding: 10,
                  transition: "all 0.3s ease-in-out",
                  boxShadow:
                    location.pathname === "/dashboard/chatbox"
                      ? "0 0 12px rgba(0, 0, 255, 0.3)"
                      : "none",
                }}
              >
                <Tooltip title="Mailbox">
                <Badge color="error">
                  <MailIcon
                    sx={{
                      color:
                        location.pathname === "/dashboard/chatbox"
                          ? "#1976d2"
                          : "inherit",
                      fontSize: 28,
                    }}
                  />
                </Badge>
                </Tooltip>
              </IconButton>
            )}

            {/* User Account Menu */}
            <Account slots={{ menuItems: CustomMenu }} className="w-fit" />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
