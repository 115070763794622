import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../services/apiClient";

// Async thunk to fetch sync status
export const fetchSyncStatus = createAsyncThunk("sync/fetchStatus", async () => {
    const response = await apiClient.get("/api/sync-status/");
    return response?.data;
});

// Async thunk to trigger sync
export const triggerSync = createAsyncThunk("sync/trigger", async () => {
    await apiClient.post("/api/trigger-sync/");
});

const syncSlice = createSlice({
    name: "sync",
    initialState: {
        isSyncing: false,
        lastSyncDateTime: null as string | null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSyncStatus.fulfilled, (state, action) => {
            state.isSyncing = action?.payload?.is_syncing || false;
            state.lastSyncDateTime = action?.payload?.last_sync_date_time;
        });
    },
});

export default syncSlice.reducer;
