import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

interface Conversation {
  conversation_id: string;
  name: string;
  lastMessage: string;
  unread_messages: number;
  pending_approvals: number;
  isGenerating?: boolean; // AI is generating a reply
}

interface Message {
  id: string;
  message?: string;
  sender: "guest" | "ai" | "host" | string;
  content: string;
  conversationId: string;
  timestamp: string; // Stores message date & time
  needs_approval?: boolean; // AI reply requires human confirmation
}

interface Property {
  name?: string;
  address?: string;
  amanities?: string[];
}

interface Reservation {
  guestName?: string;
  checkIn?: string;
  checkOut?: string;
  status?: string;
}

interface ChatState {
  conversations: Conversation[];
  messages: Record<string, Message[]>; // Maps conversationId → messages[]
  reservations: Record<string, Reservation>; // Maps conversationId → reservation details
  properties: Record<string, Property>; // Maps conversationId → property details
  selectedConversation: string | null;
}

const initialState: ChatState = {
  conversations: [],
  messages: {},
  reservations: {},
  properties: {},
  selectedConversation: null,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setConversations: (state, action: PayloadAction<Conversation[]>) => {
      state.conversations = action.payload;
    },

    /** Select a conversation */
    selectConversation: (state, action: PayloadAction<string>) => {
      if (state.selectedConversation !== action.payload) {
        state.selectedConversation = action.payload;
        state.messages[action.payload] = []; // Clear messages
        state.reservations[action.payload] = {}; // Clear reservation details
        state.properties[action.payload] = {}; // Clear property details
      }
    },

    /** Load messages for a conversation */
    setMessages: (
      state,
      action: PayloadAction<{ conversationId: string; messages: Message[] }>
    ) => {
      state.messages[action.payload.conversationId] = action.payload.messages;
    },

    /** Add a new message to a conversation */
    addMessage: (
      state,
      action: PayloadAction<{ conversationId: string; message: Message }>
    ) => {
      state.messages[action.payload.conversationId].push(
        action.payload.message
      );

      // Update last message in conversation list
      const conversation = state.conversations.find(
        (conv) => conv?.conversation_id === action.payload.conversationId
      );
      if (conversation) {
        conversation.lastMessage = action.payload.message.content;
      }
    },

    /** Approve or decline an AI-generated message */
    approveMessage: (
      state,
      action: PayloadAction<{
        conversationId: string;
        messageId: string;
        content?: string;
        declined?: boolean;
      }>
    ) => {
      const conversationMessages =
        state.messages[action.payload.conversationId];
      const message = conversationMessages.find(
        (msg) => msg.id === action.payload.messageId
      );

      if (message) {
        if (action.payload.declined) {
          message.content = "Declined by user";
        } else {
          message.content = action.payload.content!;
        }
        message.needs_approval = false;
      }
    },

    /** Toggle AI response generation status for a conversation */
    setGeneratingStatus: (
      state,
      action: PayloadAction<{ conversationId: string; isGenerating: boolean }>
    ) => {
      const conversation = state.conversations.find(
        (conv) => conv?.conversation_id === action.payload.conversationId
      );
      if (conversation) {
        conversation.isGenerating = action.payload.isGenerating;
      }
    },

    /** Set or update reservation details */
    setReservation: (
      state,
      action: PayloadAction<{
        conversationId: string;
        reservation: Reservation;
      }>
    ) => {
      state.reservations[action.payload.conversationId] =
        action.payload.reservation;
    },

    /** Set or update property details */
    setProperty: (
      state,
      action: PayloadAction<{
        conversationId: string;
        property: Property;
      }>
    ) => {
      state.properties[action.payload.conversationId] = action.payload.property;
    },
  },
});

export const {
  setConversations,
  selectConversation,
  setMessages,
  addMessage,
  approveMessage,
  setGeneratingStatus,
  setReservation,
  setProperty, // <-- Newly added action
} = chatSlice.actions;
export default chatSlice.reducer;
