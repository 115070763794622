import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Box, Alert } from "@mui/material";
import { setTokens } from "../../redux/authSlice";
import apiClient from "../../services/apiClient";
import { RootState } from "../../redux/store";
import axios from "axios";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const isActive = useSelector(
    (state: RootState) => state.auth.user?.isActive || false
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // Reset error state
    setIsLoading(true);
    try {
      // Request for the JWT tokens
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/api/token/",
        {
          username: email,
          password,
        }
      );

      const { access, refresh } = response.data;

      // Set the token in the header manually before the next request for user info
      apiClient.defaults.headers.common["Authorization"] = `Bearer ${access}`;

      // Fetch user information
      const userResponse = await apiClient.get("/api/user/");
      const user = userResponse.data;

      // Update the Redux store with user information if needed
      dispatch(
        setTokens({
          accessToken: access,
          refreshToken: refresh,
          user: {
            name: user.name,
            email: user.email,
            isActive: user.isActive,
            pms: user.pms_name
          },
        })
      );

      if (user.isActive) {
        navigate("/dashboard");
      } else {
        navigate("/register");
      }
    } catch (err) {
      setError("Invalid email or password");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken && isActive) {
      navigate("/dashboard/home");
    }
  }, [accessToken, isActive]);

  return (
    <div className="grid items-center justify-center w-screen h-screen">
      <Box
        className="border rounded-lg shadow-lg p-10"
        sx={{ bgcolor: "background.default" }}
      >
        <Typography
          variant="h4"
          gutterBottom
          className="text-center !font-extrabold pb-3"
          style={{ color: "#0066b2" }}
        >
          Login
        </Typography>
        <form onSubmit={handleSubmit} className="flex flex-col gap-3 w-80">
          <TextField
            label="Username"
            variant="outlined"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
          />
          {error && <Alert severity="error">{error}</Alert>}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            // fullWidth
            className="w-fit !mx-auto !mt-8 !text-white !cursor-pointer"
          >
            Login
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default Login;
