import apiClient from "./apiClient";

export const fetchConversations = () => apiClient.get(`/api/conversations/`);

export const fetchMessageByID = (conversationID: string) =>
  apiClient.get(`/api/messages/${conversationID}/`);

export const approveAIReply = async (
  replyId: string | number,
  content: string,
  declined = false
) => {
  return await apiClient.post("/api/approve-ai-reply/", {
    reply_id: replyId,
    content,
    declined,
  });
};

export const createManualReply = async (
  conversationId: string | number,
  messageId: string | number,
  content: string
) => {
  return await apiClient.post("/api/create-reply/", {
    conversation_id: conversationId,
    message_id: messageId,
    content,
  });
};
