import { useLayoutEffect, useRef } from "react";
import { Chip } from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import MessageBubble from "./MessageBubble";
import AITypingIndicator from "./AITypingIndicator";

const formatDate = (date: string) => {
  const today = dayjs().startOf("day");
  const messageDate = dayjs(date).startOf("day");

  if (messageDate.isSame(today)) return "Today";
  if (messageDate.isSame(today.subtract(1, "day"))) return "Yesterday";
  return dayjs(date).format("MMM D, YYYY");
};

const ChatMessages = ({
  messages,
  isGenerating,
  setModalOpen,
  handleOpenModal
}: {
  messages: any[];
  isGenerating: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenModal:(message: any) => void
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Scroll to bottom before the browser repaints
  useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages, isGenerating]);

  return (
    <div
      ref={containerRef}
      className="flex-1 overflow-y-auto p-4 bg-white rounded-lg shadow-lg"
    >
      {messages.reduce((acc: any[], msg, index) => {
        const currentDate =
          msg.sender === "guest"
            ? formatDate(msg.received_at)
            : formatDate(msg.sent_at);
        const previousDate =
          index > 0 ? formatDate(messages[index - 1].timestamp) : null;

        if (currentDate !== previousDate) {
          acc.push(
            <div key={`date-${currentDate}`} className="flex justify-center">
              <Chip label={currentDate} size="small" className="text-xs" />
            </div>
          );
        }

        // Render a sleek declined message bubble
        if (msg.ai_generated && msg.declined) {
          acc.push(
            <div key={`declined-${msg.id}`} className="flex justify-end mb-2">
              <div className="max-w-xs px-4 py-3 rounded-2xl border border-red-300 bg-gradient-to-r from-red-50 to-red-100 shadow-lg">
                <div className="flex items-center gap-2">
                  <CancelIcon fontSize="small" className="text-red-500" />
                  <span className="text-sm font-semibold text-red-700">
                    {msg.content}
                  </span>
                </div>
                {/* <p className="text-sm text-gray-700 mt-1">
                  This AI-generated response was rejected by the user.
                </p> */}
              </div>
            </div>
          );
        } else {
          acc.push(
            <MessageBubble
              key={msg.id}
              message={msg}
              setModalOpen={setModalOpen}
              handleOpenModal={handleOpenModal}
            />
          );
        }
        return acc;
      }, [])}

      {isGenerating && <AITypingIndicator />}
    </div>
  );
};

export default ChatMessages;
