import {
  ListItem,
  ListItemButton,
  ListItemText,
  Badge,
  Avatar,
} from "@mui/material";
import { darkenColor } from "../utils";

interface ConversationItemProps {
  conversation: {
    conversation_id: string;
    name: string;
    lastMessage: string;
    unread_messages: number;
    pending_approvals: number;
    avatarColor: string;
  };
  selected: boolean;
  onSelect: () => void;
}


const ConversationItem: React.FC<ConversationItemProps> = ({
  conversation,
  selected,
  onSelect,
}) => {
  return (
    <ListItem
      disablePadding
      sx={{
        backgroundColor: selected ? "#E3F2FD" : "#F8F9FA",
        borderRadius: "12px",
        marginBottom: "8px",
        transition: "background-color 0.3s ease-in-out, transform 0.2s ease",
        boxShadow: selected ? "0 4px 8px rgba(0, 0, 255, 0.1)" : "none",
        "&:hover": {
          backgroundColor: selected ? "#BBDEFB" : "#EDEDED",
          transform: "scale(1.02)",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <ListItemButton
        onClick={onSelect}
        sx={{
          padding: "14px",
          display: "flex",
          gap: "14px",
          alignItems: "center",
          borderRadius: "12px",
        }}
      >
        {/* Avatar with Badge */}
        <Badge
          badgeContent={
            conversation.pending_approvals > 0
              ? conversation.pending_approvals
              : null
          }
          color="error"
          sx={{
            "& .MuiBadge-badge": {
              fontSize: "0.75rem",
              minWidth: "18px",
              height: "18px",
              fontWeight: "bold",
            },
          }}
        >
          <Avatar
            sx={{
              bgcolor: selected
                ? darkenColor(conversation.avatarColor)
                : conversation.avatarColor,
              color: "white",
              width: "42px",
              height: "42px",
              fontSize: "1rem",
              fontWeight: "bold",
              transition: "background-color 0.2s ease-in-out", // Smooth transition
            }}
          >
            {conversation.name.charAt(0)}
          </Avatar>
        </Badge>

        {/* Text Section */}
        <div className="flex-1">
          <ListItemText
            primary={conversation.name}
            secondary={conversation.lastMessage}
            primaryTypographyProps={{
              sx: {
                fontWeight: conversation.unread_messages ? "bold" : "500",
                color: conversation.unread_messages ? "#0D47A1" : "#333",
                fontSize: "1rem",
                lineHeight: "1.2",
              },
            }}
            secondaryTypographyProps={{
              sx: {
                color: "#757575",
                fontSize: "0.85rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                maxWidth: "250px",
              },
            }}
          />
        </div>
      </ListItemButton>
    </ListItem>
  );
};

export default ConversationItem;
