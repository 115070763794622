import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { RootState } from "../../../../redux/store";
import {
  selectConversation,
  setConversations,
} from "../../../../redux/chatSlice";
import { List, Paper, Avatar, Typography, Box, Skeleton } from "@mui/material";
import SearchBar from "./SearchBar";
import ConversationItem from "./ConversationItem";
import SettingsIcon from "@mui/icons-material/Settings";
import { fetchConversations } from "../../../../services/chatAPI";
import { getRandomColor } from "../utils";

const NAVBAR_HEIGHT = 60;
const token = localStorage.getItem("accessToken");
const BACKENDBASEURL = process.env.REACT_APP_SOCKET_BASE_URL;

const WEBSOCKET_URL = `${
  window.location.protocol === "https:" ? "wss" : "ws"
}://${BACKENDBASEURL}/ws/conversation/?token=${token}`;

const ConversationList = () => {
  const dispatch = useDispatch();
  const conversations = useSelector(
    (state: RootState) => state.chat.conversations
  );
  const selectedConversation = useSelector(
    (state: RootState) => state.chat.selectedConversation
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredConversations, setFilteredConversations] =
    useState(conversations);
  const [loading, setLoading] = useState(true); // 🟢 Loading state

  /** Fetch conversations and store in Redux */
  const fetchConversationList = async () => {
    try {
      setLoading(true);
      const response = await fetchConversations(); // Fetch data from API
      dispatch(setConversations(response.data || [])); // Store in Redux
    } catch (err) {
      console.error("Failed to fetch conversations", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConversationList();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const filtered = conversations.filter((conv) =>
        conv?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredConversations(filtered);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchTerm, conversations]);

  useEffect(() => {
    const socket = new WebSocket(WEBSOCKET_URL);

    socket.onopen = () => {
      console.log("WebSocket connected");
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.event.includes("_updated")) {
        fetchConversationList();
      }
    };

    socket.onclose = () => {
      console.log("WebSocket disconnected");
    };

    return () => {
      socket.close();
    };
  }, []);

  return (
    <div
      className="w-1/4 h-screen flex flex-col relative bg-white shadow-lg rounded-lg overflow-hidden py-2 mt-4"
      style={{ height: `calc(100vh - ${NAVBAR_HEIGHT}px)` }}
    >
      {/* Header */}
      <div className="p-4 flex flex-col gap-2 border-b">
        <Typography variant="h6" fontWeight="bold" color="primary">
          Messages
        </Typography>

        {/* Search Bar */}
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>

      {/* Conversation List */}
      <div className="flex-1 overflow-y-auto">
        <List sx={{ padding: "8px" }}>
          {loading ? (
            // 🟢 Skeleton Loading
            [...Array(5)].map((_, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", gap: 2, p: 2 }}
              >
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="text" width="70%" height={20} />
              </Box>
            ))
          ) : filteredConversations.length > 0 ? (
            filteredConversations.map((conv) => (
              <ConversationItem
                key={conv?.conversation_id}
                conversation={{
                  ...conv,
                  avatarColor: getRandomColor(conv.name),
                }}
                selected={selectedConversation === conv?.conversation_id}
                onSelect={() =>
                  dispatch(selectConversation(conv?.conversation_id))
                }
              />
            ))
          ) : (
            <Typography align="center" color="textSecondary" sx={{ py: 4 }}>
              No conversations found
            </Typography>
          )}
        </List>
      </div>

      {/* Settings */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          padding: "16px",
          borderTop: "1px solid #e0e0e0",
          backgroundColor: "#F9FAFB",
          cursor: "pointer",
          transition: "background 0.3s",
          "&:hover": { backgroundColor: "#f0f0f0" },
        }}
        onClick={() => console.log("Open Settings")}
      >
        <Avatar
          sx={{
            bgcolor: "#1976D2",
            width: 40,
            height: 40,
          }}
        >
          <SettingsIcon sx={{ color: "#FFF" }} />
        </Avatar>
        <Typography variant="body1" fontWeight="bold" color="textPrimary">
          Settings & Preferences
        </Typography>
      </Box>
    </div>
  );
};

export default ConversationList;
