import { Paper, Typography, Divider, Box } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const ReservationPanel = () => {
  const selectedConversation = useSelector(
    (state: RootState) => state.chat.selectedConversation
  );
  const reservations = useSelector(
    (state: RootState) => state.chat.reservations
  );

  // Find the selected guest's reservation details
  const reservation = selectedConversation
    ? reservations[selectedConversation]
    : null;

  if (!reservation) return null; // Do not render if no guest is selected

  return (
    <div
      className="p-6 shadow-lg bg-white flex flex-col rounded-lg w-1/2"
      style={{
        width: "350px",
        borderLeft: "1px solid #ddd",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography variant="h6" fontWeight="bold" color="primary">
        Reservation Details
      </Typography>
      <Divider />

      {/* Guest Name */}
      <Box display="flex" alignItems="center" gap={1}>
        <PersonIcon color="action" />
        <Typography variant="body1" fontWeight="bold">
          {reservation.guestName}
        </Typography>
      </Box>

      {/* Check-in Date */}
      <Box display="flex" alignItems="center" gap={1}>
        <EventIcon color="action" />
        <Typography variant="body2">
          <strong>Check-in:</strong> {reservation.checkIn}
        </Typography>
      </Box>

      {/* Check-out Date */}
      <Box display="flex" alignItems="center" gap={1}>
        <EventIcon color="action" />
        <Typography variant="body2">
          <strong>Check-out:</strong> {reservation.checkOut}
        </Typography>
      </Box>

      {/* Reservation Status */}
      <Box display="flex" alignItems="center" gap={1}>
        <InfoIcon color="action" />
        <Typography variant="body2">
          <strong>Status:</strong> {reservation.status}
        </Typography>
      </Box>
    </div>
  );
};

export default ReservationPanel;
